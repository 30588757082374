const BASE_URL = "https://wisa.digitalapps.studio/backend";

export const fetchHandshakeSequence = async () => {
  const response = await fetch(`${BASE_URL}/handshake-sequence`);
  const data = await response.json();
  return data;
};

export const getCommandsList = async () => {
  const response = await fetch(`${BASE_URL}/get-commands`);
  const data = await response.json();
  return data;
};

export const getSequence = async (command) => {
  const response = await fetch(`${BASE_URL}/sequence/${command}`);
  const data = await response.json();
  if (data.success) {
    return data;
  } else {
    return false;
  }
};
