export const VERBOSE = true;

export const connectOptions = {
  baudRate: 1000000,
  dataBits: 8,
  parity: "none",
  stopBits: 1,
  flowControl: "none",
  bufferSize: 8 * 1024,
  // Prior to Chrome 86 these names were used.
  baudrate: 1000000,
  databits: 8,
  stopbits: 1,
  rtscts: "none",
};
