import { useState, useEffect, useCallback } from "react";
import { serial as polyfill } from "web-serial-polyfill";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

import { connectToPort, disconnectFromPort, handleSend, reader } from "./utils/helpers";

import Logo from "./assets/logo.png";
import { Container, ControlsContainer, LogoContainer } from "./styled";
import "./App.css";
import "xterm/css/xterm.css";
import { getCommandsList } from "./api";

export const term = new Terminal({
  scrollback: 10_000,
  fontFamily: "Neue Plak Text",
  cursorBlink: true,
  letterSpacing: 0,
  lineHeight: 1.5,
  drawBoldTextInBrightColors: true,
});
const fitAddon = new FitAddon();
term.loadAddon(fitAddon);

function App() {
  const [port, setPort] = useState("prompt");
  const [ports, setPorts] = useState([]);
  const [connected, setConnected] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState();
  const [loading, setLoading] = useState(false);
  const [commandsList, setCommandsList] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const usePolyfill = urlParams.has("polyfill");

  const findPortOption = useCallback(
    (port) => {
      for (let i = 0; i < ports.length; ++i) {
        const option = ports[i].value;
        // eslint-disable-next-line eqeqeq
        if (option == port) {
          return option;
        }
      }

      return null;
    },
    [ports]
  );

  useEffect(() => {
    const serial = usePolyfill ? polyfill : navigator.serial;

    if (serial) {
      (async () => {
        const ports = await serial.getPorts();
        setPorts([]);

        ports.forEach((port, index) => {
          const { usbVendorId } = port.getInfo();
          setPorts((restPorts) => [...restPorts, { name: `Port ${usbVendorId ?? index + 1}`, value: port }]);
        });
      })();

      if (!usePolyfill) {
        navigator.serial.addEventListener("connect", (event) => {
          // addNewPort(event.target);
          const { usbVendorId } = event.target.getInfo();
          setPorts((restPorts) => [...restPorts, { name: `Port ${usbVendorId ?? restPorts.length}`, value: event.target }]);
        });
        navigator.serial.addEventListener("disconnect", (event) => {
          const portOption = findPortOption(event.target);
          if (portOption) {
            portOption.remove();
          }
        });
      }
    }
  }, [usePolyfill, findPortOption]);

  useEffect(() => {
    term.open(document.getElementById("terminal"));

    (async () => {
      const data = await getCommandsList();
      setCommandsList(data.command);
      setSelectedCommand(data.command[0].command);
    })();

    return () => {
      if (reader) {
        reader.releaseLock();
        reader = undefined;
      }
    };
  }, []);

  return (
    <Container>
      <LogoContainer>
        <img src={Logo} alt="logo" />
      </LogoContainer>
      <ControlsContainer>
        {!connected ? (
          <select onChange={(e) => setPort(ports[parseInt(e.target.value)].value)}>
            <option value="prompt">Select port</option>
            {ports?.map((item, index) => (
              <option value={index} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        ) : (
          <select onChange={(e) => setSelectedCommand(e.target.value)}>
            {commandsList?.map((item, index) => (
              <option value={item.command} key={item.command}>
                {item.text}
              </option>
            ))}
          </select>
        )}
        {connected ? (
          <>
            <button disabled={loading} onClick={() => handleSend(port, selectedCommand, setLoading, commandsList)}>
              Send
            </button>
            <button
              disabled={loading}
              onClick={() => {
                disconnectFromPort(setPort, setConnected);
                setSelectedCommand(commandsList[0].command);
              }}
            >
              Disconnect
            </button>
          </>
        ) : (
          <button onClick={() => connectToPort(port, setPort, setConnected, commandsList)}>
            <span>Connect</span>
          </button>
        )}
        <button disabled={loading} onClick={() => term.clear()}>
          Clear
        </button>
      </ControlsContainer>
      <div id="terminal"></div>
    </Container>
  );
}

export default App;
