import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 2px 0px 5px 0px #dde5ed;
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 90px;

  img {
    width: 200px;
  }
`;

export const ControlsContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  button {
    min-height: 40px;
    padding: 0 30px 6px;
    background: #007dba;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    font-family: "Neue Plak";
  }

  select {
    width: 50%;
    min-height: 40px;
    padding: 0 10px 6px;
    font-family: "Neue Plak Text";
  }
`;
