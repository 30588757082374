/* eslint-disable no-loop-func */
import { serial as polyfill } from "web-serial-polyfill";
import { fetchHandshakeSequence, getSequence } from "../api";
import { term } from "../App";
import { connectOptions, VERBOSE } from "./constants";

export let reader;
let gPort;
let runningCommand = "";

const urlParams = new URLSearchParams(window.location.search);
const usePolyfill = urlParams.has("polyfill");

function markDisconnected(setPort) {
  term.writeln("<DISCONNECTED>");
  setPort("prompt");
  runningCommand = "";
}

export const writeAndRead = async (port, data) => {
  if (port && port.writable && port.readable) {
    const writer = port.writable.getWriter();
    const uintArr = new Uint8Array(data);
    await writer.write(uintArr);
    VERBOSE && term.writeln(`WRITE: ${uintArr}`);
    writer.releaseLock();
  }
};

export const read = async (p, commandsList) => {
  while (gPort && gPort.readable) {
    try {
      try {
        reader = gPort.readable.getReader({ mode: "byob" });
      } catch {
        reader = gPort.readable.getReader();
      }
      for (;;) {
        const { value, done } = await (async () => {
          return await reader.read();
        })();
        if (value) {
          await new Promise(async (resolve) => {
            if (value.length <= 1) {
              if (VERBOSE) {
                term.write("READ: ");
                term.writeln(value, resolve);
              } else {
                resolve();
              }
            } else {
              term.write(runningCommand ? `${commandsList.find((item) => item.command === runningCommand).text}: ` : "READ: ");
              term.writeln(value, resolve);
            }
          });
        }
        if (done) {
          break;
        }
      }
    } catch (e) {
      await new Promise((resolve) => {
        term.writeln(`<ERROR: ${e.message}>`, resolve);
      });
    } finally {
      if (reader) {
        reader.releaseLock();
        reader = undefined;
      }
    }
  }
};

export const handleSend = async (port, selectedCommand, setLoading) => {
  setLoading(true);
  runningCommand = selectedCommand;
  const {
    command: { sequence },
  } = await getSequence(selectedCommand);
  await runSequence(port, sequence);
  setLoading(false);
};

const runSequence = async (port, sequence) => {
  for (let i = 0; i < sequence.length; i++) {
    const data = sequence[i];
    await writeAndRead(port, data);
    await new Promise((resolve) => setTimeout(resolve, 50));
  }
};

const getSelectedPort = async (portSelector) => {
  if (portSelector === "prompt") {
    try {
      const serial = usePolyfill ? polyfill : navigator.serial;
      const selectedPort = await serial.requestPort({});
      return selectedPort;
    } catch (e) {
      return;
    }
  } else {
    return portSelector;
  }
};

export const connectToPort = async (selectedPort, setPort, setConnected, commandsList) => {
  const port = await getSelectedPort(selectedPort);
  if (!port) {
    return;
  }

  try {
    await port.open(connectOptions);
    term.writeln("<CONNECTED>");

    const data = await fetchHandshakeSequence();

    gPort = port;
    setPort(gPort);
    setConnected(true);
    read(gPort, commandsList);
    runSequence(gPort, data.command);
  } catch (e) {
    term.writeln(`ERROR: ${e.message}`);
    markDisconnected(setPort);

    return;
  }
};

export const disconnectFromPort = async (setPort, setConnected) => {
  const localPort = gPort;
  gPort = undefined;

  if (reader) {
    await reader.cancel();
  }

  if (localPort) {
    try {
      await localPort.close();
    } catch (e) {
      console.error(e);
      term.writeln(`ERROR: ${e.message}`);
    }
  }

  markDisconnected(setPort);
  setConnected(false);
};
